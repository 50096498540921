// ChatbotGuide.js
import React from 'react';

const ChatbotGuide = () => {
  const guideSteps = [
    "Iniziate descrivendo in dettaglio la vostra attività, i servizi offerti e il settore di riferimento.",
    "Rispondete alle domande generate dal chatbot fornendo informazioni complete e dettagliate.",
    "Per ogni risposta, includete dettagli specifici della vostra azienda e esempi concreti.",
    "Dopo le domande iniziali, aggiungete domande personalizzate digitando 'Voglio aggiungere una domanda'.",
    "Per ogni domanda aggiunta, fornite una risposta dettagliata seguendo lo stesso stile delle precedenti.",
    "Verificate la coerenza delle risposte e il tono utilizzato attraverso l'anteprima fornita.",
    "Aggiungete ulteriori dettagli o varianti delle domande per arricchire il dataset.",
    "Al termine, scaricate il dataset completo e verificate tutte le domande e risposte."
  ];

  return (
    <div className="space-y-6">
      <div className="prose dark:prose-invert max-w-none">
        <p className="text-gray-700 dark:text-gray-300">
          Il nostro Chatbot Assistente vi guida nella creazione di un dataset personalizzato attraverso una conversazione 
          naturale. È progettato per comprendere la vostra attività e generare domande pertinenti per il vostro settore.
        </p>
        <div className="bg-yellow-50 dark:bg-yellow-900/30 p-4 rounded-lg mt-4">
          <h4 className="text-yellow-800 dark:text-yellow-300 font-medium">
            Suggerimenti per risposte ottimali:
          </h4>
          <ul className="list-disc pl-6 text-yellow-700 dark:text-yellow-400">
            <li>Fornite risposte dettagliate e complete</li>
            <li>Includete esempi specifici quando possibile</li>
            <li>Aggiungete informazioni di contesto rilevanti</li>
            <li>Utilizzate un tono coerente con il vostro brand</li>
            <li>Non limitate le risposte alle 10 domande base</li>
            <li>Aggiungete tutte le informazioni che ritenete utili</li>
            <li>Includete variazioni comuni delle stesse domande</li>
          </ul>
        </div>
      </div>

      <div className="space-y-4">
        {guideSteps.map((step, i) => (
          <div key={i} className="flex items-start space-x-2">
            <span className="font-bold min-w-[24px] text-gray-700 dark:text-gray-300">
              {i + 1}.
            </span>
            <span className="text-gray-600 dark:text-gray-400">
              {step}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatbotGuide;
