import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import './index.css';
import DashboardHeader from './DashboardHeader';

import { 
  ArrowDownTrayIcon, 
  ArrowUpTrayIcon, 
  DocumentIcon, 
  ExclamationCircleIcon,
  CheckCircleIcon,
  CpuChipIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/24/outline';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const Dashboard = ({ onLogout, isDarkMode }) => {
  // State variables for managing file upload and processing
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [purpose] = useState('assistenza_clienti_primo_livello');
  const [templateFormat, setTemplateFormat] = useState('csv');
  const [messages, setMessages] = useState([]);
  const [jsonFilePath, setJsonFilePath] = useState(null);
  const [jsonPreview, setJsonPreview] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [previewData, setPreviewData] = useState([]);
  
  // State variables for dataset expansion
  const [useExpansion, setUseExpansion] = useState(true);
  const [numVariants, setNumVariants] = useState(10);
  
  
  // State variables for fine-tuning process
  const [fineTuneStatus, setFineTuneStatus] = useState(null);
  const [isFineTuning, setIsFineTuning] = useState(false);

  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [showInfoMessage, setShowInfoMessage] = useState(false);
    
    
  // Controlla che il valore inserito dall'utente sia almeno 10
  const handleNumVariantsChange = (e) => {
  const value = parseInt(e.target.value);
  setNumVariants(value >= 10 ? value : 10); // Imposta 10 come minimo
  };    
  // Callback function for appending messages
  const appendMessage = useCallback((message, isUser = false) => {
    setMessages(prevMessages => [...prevMessages, { content: message, isUser }]);
  }, []);

  // Effect for tracking elapsed time during fine-tuning
  useEffect(() => {
    let timer;
    if (isFineTuning && startTime) {
      timer = setInterval(() => {
        const currentTime = Date.now();
        const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
        setElapsedTime(elapsedSeconds);
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isFineTuning, startTime]);

  // Helper function to format elapsed time
  const formatElapsedTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

 
  // Template download handler
  const handleDownloadTemplate = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      return;
    }
    
    try {
      const response = await axios.get(`${API_BASE_URL}/download_template/${purpose}/${templateFormat}`, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const fileExtension = templateFormat === 'word' ? 'docx' : templateFormat;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${purpose}_template.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      appendMessage('Template scaricato con successo. Puoi usarlo come riferimento per preparare il tuo file.', false);
    } catch (error) {
      console.error('Errore durante il download del template:', error);
      appendMessage('Errore durante il download del template. Riprova più tardi.', false);
    }
  };

  // File selection handler
  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };

  // File upload and preview handler
  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) {
      appendMessage('Per favore, seleziona almeno un file da caricare.', false);
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('purpose', purpose);
    formData.append('use_expansion', useExpansion);
    formData.append('num_variants', numVariants);

    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/upload_preview`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.data.results) {
        response.data.results.forEach((result) => {
          if (result.error) {
            appendMessage(`Errore nel file ${result.filename}: ${result.error}`, false);
          } else {
            appendMessage(`File caricato: ${result.filename}`, false);

            if (result.preview) {
              setPreviewData(result.preview);
            }

            if (result.problems && result.problems.length > 0) {
              appendMessage(`Problemi rilevati: ${result.problems.join(', ')}`, false);
            }

            if (result.improvement_suggestions && result.improvement_suggestions.length > 0) {
              appendMessage(`Suggerimenti di miglioramento: ${result.improvement_suggestions.join(', ')}`, false);
            }

            if (result.removed_rows && result.removed_rows.length > 0) {
              appendMessage(`Attenzione: Sono state rimosse ${result.removed_rows.length} righe per dati incompleti.`, false);
              appendMessage(`Dettagli delle righe rimosse: ${result.removed_rows.join(', ')}`, false);
            }

            if (result.critical_problems && result.critical_problems.length > 0) {
              appendMessage(`Sono stati rilevati problemi critici: ${result.critical_problems.join(', ')}`, false);
              appendMessage(`Vuoi correggere il file o procedere comunque?`, false);

              appendMessage(
                <div className="mt-2 space-x-2">
                  <button 
                    onClick={() => handleCorrectFile(result.filename)}
                    className={`px-4 py-2 text-white rounded transition-colors duration-200 ${
                      isDarkMode ? 'bg-yellow-600 hover:bg-yellow-700' : 'bg-yellow-500 hover:bg-yellow-600'
                    }`}
                  >
                    Correggi File
                  </button>
                  <button 
                    onClick={() => handleProceedAnyway(result.filename)}
                    className={`px-4 py-2 text-white rounded transition-colors duration-200 ${
                      isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'
                    }`}
                  >
                    Procedi comunque
                  </button>
                </div>,
                false
              );
            } else {
              appendMessage('Il file è valido e pronto per il fine-tuning.', false);
              appendMessage(
                <button
                  onClick={handleGenerateJson}
                  className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${
                    isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'
                  } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-4`}
                >
                  Procedi
                </button>,
                false
              );
            }
          }
        });
      }
    } catch (error) {
      console.error('Errore durante il caricamento dei file:', error);
      appendMessage(`Errore durante il caricamento dei file: ${error.response?.data?.detail || error.message}`, false);
    }
  };

  // File correction handler
  const handleCorrectFile = (filename) => {
    appendMessage(`Per favore, correggi il file originale e ricaricalo.`, false);
  };

  // Handler for proceeding despite errors
  const handleProceedAnyway = async (filename) => {
    appendMessage(`Hai scelto di procedere con il fine-tuning per il file ${filename} nonostante i problemi rilevati. Generazione del JSON in corso...`, false);
    try {
      await handleGenerateJson(true);
    } catch (error) {
      appendMessage('Errore durante la generazione del JSON. Verifica i dati e riprova.', false);
      console.error('Errore durante handleProceedAnyway:', error);
    }
  };

  // JSON generation handler
  const handleGenerateJson = async (fromProceedAnyway = false) => {
    if (selectedFiles.length === 0) {
      appendMessage('Per favore, seleziona almeno un file da processare.', false);
      return;
    }

    setIsProcessing(true);
    setProgress(0);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('purpose', purpose);
    formData.append('use_expansion', useExpansion);
    formData.append('num_variants', numVariants);

    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      setIsProcessing(false);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/convert_to_json`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.task_id) {
        appendMessage(`Processo di conversione avviato. ID del task: ${response.data.task_id}`, false);
        await pollTaskStatus(response.data.task_id);
      } else {
        appendMessage('Errore: Il processo di conversione non è stato avviato correttamente.', false);
      }
    } catch (error) {
      appendMessage('Errore durante la generazione del file JSON. Riprova più tardi.', false);
    } finally {
      setIsProcessing(false);
    }
  };
  // Task status polling
  const pollTaskStatus = async (taskId) => {
  const maxAttempts = 180; // Aumentato a 180 tentativi (15 minuti con intervallo di 5 secondi)
  const interval = 5000; // 5 secondi tra ogni tentativo
  let lastProgress = 0;
  let lastMessageTime = Date.now();
  
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      const response = await axios.get(`${API_BASE_URL}/task_status/${taskId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 30000 // 30 secondi di timeout per ogni richiesta
      });

      if (response.data.state === 'PROCESSING') {
        // Calcola il progresso in modo più graduale
        const currentProgress = Math.min(80, 20 + (attempt / maxAttempts) * 60);
        if (currentProgress > lastProgress) {
          setProgress(currentProgress);
          lastProgress = currentProgress;
        }

        // Mostra messaggi di stato ogni 30 secondi per evitare spam
        const currentTime = Date.now();
        if (currentTime - lastMessageTime > 30000) {
          appendMessage(
            `Elaborazione in corso: ${response.data.status || 'Generazione del dataset in corso...'}` +
            (useExpansion ? ' Questo processo potrebbe richiedere alcuni minuti.' : ''),
            false
          );
          lastMessageTime = currentTime;
        }
      } else if (response.data.state === 'SUCCESS') {
        setProgress(100);
        appendMessage(`Processo completato con successo. File JSON generato.`, false);
        
        if (response.data.json_file_path) {
          setJsonFilePath(response.data.json_file_path);
          appendMessage(`File JSON pronto per il fine-tuning: ${response.data.json_file_path}`, false);

          if (response.data.json_preview && response.data.json_preview.length > 0) {
            setJsonPreview(response.data.json_preview);
          }

          appendMessage(
            <button
              onClick={() => handleSendForFinetuning(response.data.json_file_path)}
              className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${
                isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-600 hover:bg-blue-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-4`}
            >
              <CpuChipIcon className="mr-2 h-4 w-4" />
              Invia per Fine-Tuning
            </button>,
            false
          );
        }
        return;
      } else if (response.data.state === 'FAILURE') {
        setProgress(0);
        appendMessage(`Errore durante la generazione del JSON: ${response.data.status}`, false);
        return;
      }

      await new Promise(resolve => setTimeout(resolve, interval));
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        // Timeout della singola richiesta, continua con il polling
        console.warn('Timeout della richiesta, ritento...');
        if (attempt % 3 === 0) { // Mostra messaggio ogni 3 tentativi
          appendMessage(
            'Il processo è ancora in corso. Continuo a monitorare lo stato...',
            false
          );
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
        continue;
      }

      // Verifica se è un errore di rete
      if (!navigator.onLine) {
        appendMessage('Connessione di rete persa. In attesa di riconnessione...', false);
        // Attendi che la connessione torni prima di continuare
        await new Promise(resolve => {
          const checkConnection = () => {
            if (navigator.onLine) {
              appendMessage('Connessione ripristinata. Riprendo il monitoraggio...', false);
              resolve();
            } else {
              setTimeout(checkConnection, 1000);
            }
          };
          checkConnection();
        });
        continue;
      }

      // Per altri tipi di errori, continua il polling
      console.error('Errore durante il polling:', error);
      if (attempt % 3 === 0) { // Limita i messaggi di errore
        appendMessage(
          'Si è verificato un errore temporaneo. Continuo a monitorare il processo...',
          false
        );
      }
      await new Promise(resolve => setTimeout(resolve, interval));
      continue;
    }
  }

  // Se raggiungiamo il numero massimo di tentativi
  appendMessage(
    'Il processo sta richiedendo più tempo del previsto. ' +
    'Continuerà in background e riceverai una notifica quando sarà completato. ' +
    'Non chiudere questa finestra.',
    false
  );
  setProgress(95); // Mantiene il progresso alto per indicare che è ancora in corso
};

  const handleSendForFinetuning = async (jsonFilePathParam) => {
  if (typeof jsonFilePathParam !== 'string') {
    appendMessage('Errore: Il percorso del file JSON non è valido.', false);
    return;
  }

  try {
    setIsFineTuning(true);
    setProgress(0);
    setStartTime(Date.now());
    setElapsedTime(0);
    setShowInfoMessage(true);
    
    // Messaggio iniziale più dettagliato
    appendMessage(
      'Avvio del processo di fine-tuning...\n' +
      'Questo processo richiederà diversi minuti. ' +
      'Puoi monitorare il progresso in questa finestra.',
      false
    );

    const formData = new FormData();
    formData.append('json_file_path', jsonFilePathParam);
    formData.append('purpose', purpose);

    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      setIsFineTuning(false);
      setProgress(0);
      return;
    }

    // Aggiunto timeout e retry logic per la richiesta iniziale
    let attempts = 0;
    const maxAttempts = 3;
    
    while (attempts < maxAttempts) {
      try {
        const res = await axios.post(`${API_BASE_URL}/process_finetuning`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
          timeout: 60000, // 60 secondi di timeout per l'invio iniziale
        });

        if (res.data.temporary_job_id) {
          const temporaryJobId = res.data.temporary_job_id;
          appendMessage(
            <div>
              <p>Fine-tuning avviato con successo!</p>
              <p className="mt-2 text-sm opacity-75">ID Temporaneo: {temporaryJobId}</p>
              <p className="mt-2">
                Il processo completo potrebbe richiedere da 15 a 30 minuti. 
                Monitorerai il progresso in questa finestra.
              </p>
            </div>,
            false
          );
          setProgress(5);
          pollRealJobId(temporaryJobId);
          return;
        } else {
          throw new Error('ID temporaneo del job non ricevuto dal server');
        }
      } catch (error) {
        attempts++;
        
        if (error.code === 'ECONNABORTED') {
          appendMessage('Il server sta impiegando più tempo del previsto a rispondere...', false);
        }

        if (!navigator.onLine) {
          appendMessage('Connessione di rete persa. In attesa di riconnessione...', false);
          // Attendi che la connessione torni
          await new Promise(resolve => {
            const checkConnection = () => {
              if (navigator.onLine) {
                resolve();
              } else {
                setTimeout(checkConnection, 1000);
              }
            };
            checkConnection();
          });
        }

        if (attempts < maxAttempts) {
          appendMessage(`Tentativo ${attempts} di ${maxAttempts}. Riprovo...`, false);
          await new Promise(resolve => setTimeout(resolve, 3000 * attempts)); // Backoff incrementale
          continue;
        }

        throw error; // Se tutti i tentativi falliscono, propaga l'errore
      }
    }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message;
    appendMessage(
      <div>
        <p className="text-red-500">Errore durante l'avvio del fine-tuning:</p>
        <p className="mt-2">{errorMessage}</p>
        <p className="mt-2">
          Suggerimenti:
          <ul className="list-disc ml-4 mt-1">
            <li>Verifica la tua connessione internet</li>
            <li>Controlla che il file JSON sia valido</li>
            <li>Riprova tra qualche minuto</li>
          </ul>
        </p>
      </div>,
      false
    );
    setProgress(0);
    setIsFineTuning(false);
    setShowInfoMessage(false);
  }
};

  const pollRealJobId = async (temporaryJobId) => {
  const maxRetries = 60; // Aumentato a 60 tentativi (5 minuti con intervallo di 5 secondi)
  const interval = 5000;
  let attempts = 0;
  let lastMessageTime = Date.now();

  const pollInterval = setInterval(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get_real_job_id/${temporaryJobId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 30000 // 30 secondi di timeout per ogni richiesta
      });

      if (response.data.real_job_id) {
        clearInterval(pollInterval);
        appendMessage(
          <div>
            <p>✓ Job ID OpenAI ottenuto con successo</p>
            <p className="text-sm opacity-75 mt-1">ID: {response.data.real_job_id}</p>
            <p className="mt-2">Avvio monitoraggio del processo di fine-tuning...</p>
          </div>,
          false
        );
        setProgress(50);
        monitorFineTuneStatus(response.data.real_job_id);
        return;
      } else if (response.data.status === 'processing') {
        // Aggiorna il progresso in modo più graduale
        setProgress(Math.min(45, 5 + (attempts / maxRetries) * 40));
        
        // Mostra messaggi di stato meno frequentemente
        const currentTime = Date.now();
        if (currentTime - lastMessageTime > 30000) { // Ogni 30 secondi
          appendMessage('Creazione del job di fine-tuning in corso...', false);
          lastMessageTime = currentTime;
        }
      } else {
        // Per altri stati di risposta inattesi
        console.warn('Stato inatteso ricevuto:', response.data);
      }

      attempts++;
      if (attempts >= maxRetries) {
        clearInterval(pollInterval);
        appendMessage(
          <div>
            <p>Il processo sta richiedendo più tempo del previsto.</p>
            <p className="mt-2">Il fine-tuning continuerà in background e riceverai una notifica al completamento.</p>
            <p className="mt-2">Puoi:</p>
            <ul className="list-disc ml-4 mt-1">
              <li>Continuare a monitorare questa pagina</li>
              <li>Controllare lo stato più tardi dal dashboard</li>
              <li>Attendere la notifica di completamento</li>
            </ul>
          </div>,
          false
        );
        setProgress(95);
      }
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        // Timeout della singola richiesta, continua
        console.warn('Timeout della richiesta, ritento...');
        return;
      }

      if (!navigator.onLine) {
        appendMessage('Connessione di rete persa. In attesa di riconnessione...', false);
        
        // Attendi che la connessione torni
        const checkConnection = () => {
          if (navigator.onLine) {
            appendMessage('Connessione ripristinata. Riprendo il monitoraggio...', false);
            return;
          }
          setTimeout(checkConnection, 1000);
        };
        checkConnection();
        return;
      }

      const errorMessage = error.response?.data?.detail || error.message;
      console.error('Errore durante il polling:', error);

      // Per errori gravi, interrompi il polling
      if (error.response?.status === 404 || error.response?.status === 401) {
        clearInterval(pollInterval);
        appendMessage(
          <div>
            <p className="text-red-500">Errore durante il monitoraggio del job:</p>
            <p className="mt-2">{errorMessage}</p>
            <p className="mt-2">Si prega di riprovare più tardi o contattare il supporto.</p>
          </div>,
          false
        );
        setProgress(0);
        setIsFineTuning(false);
      } else {
        // Per altri errori, mostra il messaggio ma continua il polling
        appendMessage(
          'Si è verificato un errore temporaneo. Continuo a monitorare il processo...',
          false
        );
      }
    }
  }, interval);

  // Cleanup function
  return () => {
    clearInterval(pollInterval);
  };
};




// funzione per mostrare gli status del fine-tuning e del tempo trascorso
  const monitorFineTuneStatus = (jobId) => {
  const MAX_RETRIES = 180;  // 30 minuti totali con intervallo di 10 secondi
  const POLLING_INTERVAL = 10000;  // 10 secondi
  let retryCount = 0;
  let lastSuccessfulCheck = Date.now();
  let pollingIntervalId = null;

  const token = localStorage.getItem('token');
  if (!token) {
    appendMessage('Token mancante. Effettua nuovamente il login.', false);
    return;
  }

  const checkStatus = async () => {
    try {
      if (retryCount >= MAX_RETRIES) {
        clearInterval(pollingIntervalId);
        appendMessage(
          'Il monitoraggio diretto è terminato per timeout. ' +
          'Il processo continua in background. ' +
          'Controlla lo stato più tardi dal dashboard.',
          false
        );
        setIsFineTuning(false);
        return;
      }

      // Verifica connessione di rete
      if (!navigator.onLine) {
        appendMessage('Connessione di rete persa. In attesa di riconnessione...', false);
        await new Promise(resolve => {
          const checkConnection = setInterval(() => {
            if (navigator.onLine) {
              clearInterval(checkConnection);
              appendMessage('Connessione ripristinata. Riprendo il monitoraggio...', false);
              resolve();
            }
          }, 1000);
        });
        return; // Il prossimo intervallo riproverà
      }

      console.log("Invio richiesta di stato per il fine-tuning...");
      const response = await axios.get(`${API_BASE_URL}/fine_tune_status`, {
        headers: { 'Authorization': `Bearer ${token}` },
        timeout: 30000 // 30 secondi timeout per la singola richiesta
      });

      console.log("Risposta ricevuta dal server:", response.data);

      if (!response.data || !response.data.jobs) {
        throw new Error('Risposta non valida dal server');
      }

      const job = response.data.jobs.find(j => j.job_id === jobId);

      if (!job) {
        // Se il job non viene trovato, verifica quanto tempo è passato
        const timeSinceLastSuccess = Date.now() - lastSuccessfulCheck;
        if (timeSinceLastSuccess > 300000) { // 5 minuti
          throw new Error('Job non trovato dopo multipli tentativi');
        }
        retryCount++;
        return; // Continua il polling
      }

      // Reset dei contatori perché abbiamo ricevuto una risposta valida
      lastSuccessfulCheck = Date.now();
      retryCount = 0;

      console.log(`Stato del job trovato: ${job.status}`);
      setFineTuneStatus(job.status);
      
      // Gestione stati intermedi aggiuntivi
      const progressMapping = {
        'created': 5,
        'validating_files': 10,
        'queued': 20,
        'preparing': 30,
        'running': prevProgress => Math.min(90, prevProgress + 1),
        'succeeded': 100,
        'failed': 0,
        'cancelled': 0
      };

      if (progressMapping.hasOwnProperty(job.status)) {
        const newProgress = progressMapping[job.status];
        if (typeof newProgress === 'function') {
          setProgress(prevProgress => newProgress(prevProgress));
        } else {
          setProgress(newProgress);
        }
      }

      switch (job.status) {
        case 'succeeded':
          if (job.fine_tuned_model) {
            appendMessage(
              <div className={`transition-colors duration-200`}>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-900'}`}>
                  Fine-tuning completato con successo!
                </p>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-900'}`}>
                  Modello fine-tuned:
                </p>
                <pre className={`p-2 rounded mt-2 transition-colors duration-200 ${
                  isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-800'
                }`}>
                  {job.fine_tuned_model}
                </pre>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(job.fine_tuned_model);
                    appendMessage('ID del modello copiato negli appunti!', false);
                  }}
                  className={`mt-2 px-4 py-2 text-white rounded transition-colors duration-200 ${
                    isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
                  }`}
                >
                  Copia ID Modello
                </button>
              </div>,
              false
            );
            clearInterval(pollingIntervalId);
            setIsFineTuning(false);
            setShowInfoMessage(false);
          }
          break;

        case 'failed':
          appendMessage(
            <div>
              <p className="text-red-500">Il fine-tuning è fallito.</p>
              {job.error && (
                <p className="mt-2 text-sm">Dettaglio errore: {job.error}</p>
              )}
              <p className="mt-2">Si prega di verificare i log e riprovare.</p>
            </div>,
            false
          );
          clearInterval(pollingIntervalId);
          setIsFineTuning(false);
          setShowInfoMessage(false);
          break;

        case 'running':
          appendMessage(
            <div>
              <p>Stato del fine-tuning: {job.status}</p>
              <p className="text-sm mt-1">Job ID: {job.job_id}</p>
              <p className="text-sm mt-1">Tempo trascorso: {formatElapsedTime(elapsedTime)}</p>
            </div>,
            false
          );
          break;

        default:
          appendMessage(`Stato del fine-tuning: ${job.status}. Job ID: ${job.job_id}`, false);
      }

    } catch (error) {
      console.error('Errore durante il monitoraggio:', error);
      retryCount++;

      // Gestione specifica degli errori
      if (error.code === 'ECONNABORTED') {
        appendMessage('Timeout della richiesta. Riprovo...', false);
        return; // Riprova al prossimo intervallo
      }

      if (retryCount >= MAX_RETRIES) {
        clearInterval(pollingIntervalId);
        appendMessage(
          'Troppi errori consecutivi nel monitoraggio. ' +
          'Il processo continua in background. ' +
          'Controlla lo stato più tardi dal dashboard.',
          false
        );
        setIsFineTuning(false);
      } else if (retryCount % 3 === 0) { // Mostra errore ogni 3 tentativi
        appendMessage(`Errore nel monitoraggio (tentativo ${retryCount}). Riprovo...`, false);
      }
    }
  };

  // Avvia il polling
  pollingIntervalId = setInterval(checkStatus, POLLING_INTERVAL);
  console.log(`Inizio polling per lo stato del fine-tuning con jobId: ${jobId}`);
  checkStatus(); // Prima chiamata immediata

  // Cleanup function
  return () => {
    if (pollingIntervalId) {
      clearInterval(pollingIntervalId);
    }
  };
};
return (
    <div className={`min-h-screen transition-colors duration-200 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <DashboardHeader isDarkMode={isDarkMode} onLogout={onLogout} />
      <div className="py-2 max-w-2xl mx-auto">
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <div className={`shadow overflow-hidden sm:rounded-lg transition-colors duration-200 ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              }`}>
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-1 gap-4">
                    {/* Dataset Expansion Options */}
                    <div className="mt-4">
                      <h3 className={`text-lg font-medium transition-colors duration-200 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Opzioni di espansione del dataset</h3>
                      <div className="mt-2">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            checked={useExpansion}
                            onChange={(e) => setUseExpansion(e.target.checked)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className={`ml-2 transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-300' : 'text-gray-700'
                          }`}>Usa espansione del dataset</span>
                        </label>
                      </div>
                      {useExpansion && (
                        <div className="mt-2">
                          <label className={`block text-sm font-medium transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-300' : 'text-gray-700'
                          }`}>
                            Numero di varianti per domanda/risposta
                          </label>
                          <input
                            type="number"
                            min="10"
                            max="100"
                            value={numVariants}
                            onChange={(e) => setNumVariants(parseInt(e.target.value))}
                            className={`mt-1 block w-full rounded-md shadow-sm transition-colors duration-200 ${
                              isDarkMode 
                                ? 'bg-gray-700 border-gray-600 text-white' 
                                : 'border-gray-300 text-gray-900'
                            } focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                          />
                        </div>
                      )}
                    </div>

                    {/* Template Format Selection */}
                    <div className="mt-4">
                      <label className={`block text-sm font-medium transition-colors duration-200 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-700'
                      }`}>
                        Seleziona il formato del template
                      </label>
                      <select
                        className={`mt-1 block w-full pl-3 pr-10 py-2 text-base rounded-md transition-colors duration-200 ${
                          isDarkMode 
                            ? 'bg-gray-700 border-gray-600 text-white' 
                            : 'border-gray-300 text-gray-900'
                        } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        onChange={(e) => setTemplateFormat(e.target.value)}
                      >
                        <option value="csv">CSV</option>
                        <option value="word">Word</option>
                        <option value="pdf">PDF</option>
                      </select>
                    </div>

                    {/* Template Download Button */}
                    <div className="mt-4">
                      <button
                        onClick={handleDownloadTemplate}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <ArrowDownTrayIcon className="mr-2 h-4 w-4" />
                        Scarica Template
                      </button>
                    </div>

                    {/* File Upload Section */}
                    <div>
                      <label className={`block text-sm font-medium transition-colors duration-200 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-700'
                      }`}>
                        Carica file
                      </label>
                      <div className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md transition-colors duration-200 ${
                        isDarkMode 
                          ? 'border-gray-600 bg-gray-700' 
                          : 'border-gray-300 bg-white'
                      }`}>
                        <div className="space-y-1 text-center">
                          <ArrowUpTrayIcon className={`mx-auto h-8 w-8 transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-500' : 'text-gray-400'
                          }`} />
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className={`relative cursor-pointer rounded-md font-medium transition-colors duration-200 ${
                                isDarkMode 
                                  ? 'text-indigo-400 hover:text-indigo-300' 
                                  : 'text-indigo-600 hover:text-indigo-500'
                              } focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500`}
                            >
                              <span>Carica un file</span>
                              <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileChange} multiple />
                            </label>
                            <p className={`pl-1 transition-colors duration-200 ${
                              isDarkMode ? 'text-gray-400' : 'text-gray-600'
                            }`}>o trascina e rilascia</p>
                          </div>
                          <p className={`text-xs transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-400' : 'text-gray-500'
                          }`}>
                            CSV, Excel, Word, PDF fino a 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Selected Files List */}
                    {selectedFiles.length > 0 && (
                      <div>
                        <h4 className={`text-sm font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-gray-300' : 'text-gray-700'
                        }`}>File selezionati:</h4>
                        <ul className={`mt-2 border rounded-md divide-y transition-colors duration-200 ${
                          isDarkMode 
                            ? 'border-gray-600 divide-gray-600' 
                            : 'border-gray-200 divide-gray-200'
                        }`}>
                          {selectedFiles.map((file, index) => (
                            <li key={index} className="pl-3 pr-4 py-2 flex items-center justify-between text-sm">
                              <div className="w-0 flex-1 flex items-center">
                                <DocumentIcon className={`flex-shrink-0 h-5 w-5 transition-colors duration-200 ${
                                  isDarkMode ? 'text-gray-400' : 'text-gray-400'
                                }`} />
                                <span className={`ml-2 flex-1 w-0 truncate transition-colors duration-200 ${
                                  isDarkMode ? 'text-gray-300' : 'text-gray-900'
                                }`}>
                                  {file.name}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {/* Upload Button */}
                    <div className="flex space-x-4">
                      <button
                        onClick={handleFileUpload}
                        disabled={isProcessing}
                        className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${
                          isProcessing 
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : isDarkMode 
                              ? 'bg-blue-600 hover:bg-blue-700' 
                              : 'bg-blue-600 hover:bg-blue-700'
                        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                      >
                        <ArrowUpTrayIcon className="mr-2 h-4 w-4" />
                        {isProcessing ? 'Elaborazione in corso...' : 'Carica File e Visualizza Anteprima'}
                      </button>
                    </div>

                    {/* Data Preview */}
                    {previewData.length > 0 && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Anteprima dei Dati</h3>
                        <div className={`mt-2 overflow-x-auto transition-colors duration-200 ${
                          isDarkMode ? 'bg-gray-800' : 'bg-white'
                        }`}>
                          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            <thead className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
                              <tr>
                                {Object.keys(previewData[0]).map((col) => (
                                  col !== 'status' && (
                                    <th key={col} className={`px-4 py-2 text-left text-sm font-medium transition-colors duration-200 ${
                                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                                    } border-b dark:border-gray-600`}>
                                      {col.charAt(0).toUpperCase() + col.slice(1)}
                                    </th>
                                  )
                                ))}
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                              {previewData.map((row, index) => (
                                <tr key={index} className={`${
                                  row.status === 'valid' 
                                    ? isDarkMode ? 'bg-green-900 bg-opacity-20' : 'bg-green-100' 
                                    : isDarkMode ? 'bg-red-900 bg-opacity-20' : 'bg-red-100'
                                }`}>
                                  {Object.keys(row).map((col) => (
                                    col !== 'status' && (
                                      <td key={col} className={`border px-4 py-2 text-sm transition-colors duration-200 ${
                                        isDarkMode ? 'text-gray-300 border-gray-700' : 'text-gray-800 border-gray-200'
                                      }`}>
                                        {row[col]}
                                      </td>
                                    )
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    {/* Processing Progress */}
                    {isProcessing && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Progresso elaborazione</h3>
                        <div className="mt-2 relative pt-1">
                          <div className={`overflow-hidden h-2 mb-4 text-xs flex rounded transition-colors duration-200 ${
                            isDarkMode ? 'bg-blue-900' : 'bg-blue-200'
                          }`}>
                            <div 
                              style={{ width: `${progress}%` }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500 ease-in-out"
                            ></div>
                          </div>
                          <div className="text-right">
                            <span className={`text-sm font-semibold inline-block transition-colors duration-200 ${
                              isDarkMode ? 'text-blue-400' : 'text-blue-600'
                            }`}>
                              {progress}%
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* JSON Preview */}
                    {jsonPreview && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Anteprima del JSONL generato</h3>
                        <pre className={`mt-2 p-4 border rounded-md text-sm overflow-auto max-h-60 transition-colors duration-200 ${
                          isDarkMode 
                            ? 'bg-gray-800 border-gray-700 text-gray-300' 
                            : 'bg-gray-100 border-gray-200 text-gray-800'
                        }`}>
                          {jsonPreview.map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </pre>
                      </div>
                    )}

                    {/* System Messages */}
                    {messages.length > 0 && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Messaggi del sistema</h3>
                        <div className={`mt-2 border rounded-md divide-y max-h-60 overflow-y-auto transition-colors duration-200 ${
                          isDarkMode 
                            ? 'border-gray-700 divide-gray-700' 
                            : 'border-gray-200 divide-gray-200'
                        }`}>
                          {messages.map((message, index) => (
                            <div key={index} className={`p-3 transition-colors duration-200 ${
                              message.isUser 
                                ? isDarkMode ? 'bg-blue-900 bg-opacity-50' : 'bg-blue-50'
                                : isDarkMode ? 'bg-gray-800' : 'bg-white'
                            }`}>
                              <p className={`text-sm flex items-start transition-colors duration-200 ${
                                isDarkMode ? 'text-gray-300' : 'text-gray-700'
                              }`}>
                                {message.isUser ? (
                                  <ExclamationCircleIcon className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                                ) : (
                                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                                )}
                                {message.content}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Fine-tuning Status */}
                    {fineTuneStatus && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Stato del Fine-Tuning</h3>
                        <div className="mt-2">
                          {fineTuneStatus === 'running' && (
                            <div className="flex items-center">
                              <div className="w-5 h-5 mr-2 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                              <p className={`transition-colors duration-200 ${
                                isDarkMode ? 'text-gray-300' : 'text-gray-700'
                              }`}>Fine-tuning in corso...</p>
                            </div>
                          )}
                          {fineTuneStatus === 'succeeded' && (
                            <div className="flex items-center">
                              <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2" />
                              <p className={`transition-colors duration-200 ${
                                isDarkMode ? 'text-green-400' : 'text-green-700'
                              }`}>Fine-tuning completato con successo!</p>
                            </div>
                          )}
                          {fineTuneStatus === 'failed' && (
                            <div className="flex items-center">
                              <ExclamationCircleIcon className="h-6 w-6 text-red-500 mr-2" />
                              <p className={`transition-colors duration-200 ${
                                isDarkMode ? 'text-red-400' : 'text-red-700'
                              }`}>Fine-tuning fallito. Riprova più tardi.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Fine-tuning Progress */}
                    {isFineTuning && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Progresso del Fine-Tuning</h3>
                        <div className="mt-2 relative pt-1">
                          <div className={`overflow-hidden h-2 mb-4 text-xs flex rounded transition-colors duration-200 ${
                            isDarkMode ? 'bg-blue-900' : 'bg-blue-200'
                          }`}>
                            <div 
                              style={{ width: `${progress}%` }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500 ease-in-out"
                            ></div>
                          </div>
                          <div className="text-right">
                            <span className={`text-sm font-semibold inline-block transition-colors duration-200 ${
                              isDarkMode ? 'text-blue-400' : 'text-blue-600'
                            }`}>
                              {progress}%
                            </span>
                          </div>
                        </div>
                        <p className={`text-sm mt-2 transition-colors duration-200 ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                          Tempo trascorso: {formatElapsedTime(elapsedTime)}
                        </p>
                        {showInfoMessage && (
                          <p className={`text-sm mt-2 transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-400' : 'text-gray-600'
                          }`}>
                            Il processo di fine-tuning può richiedere molto tempo, specialmente durante la fase 'running'. Si prega di pazientare.
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;