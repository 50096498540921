import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon, BeakerIcon } from '@heroicons/react/24/outline';
import PlaygroundGuide from './PlaygroundGuide';

const PlaygroundHeader = ({ isDarkMode }) => {
  const [showGuide, setShowGuide] = useState(false);

  return (
    <>
      {/* Top Navigation Bar */}
      <div className={`transition-colors duration-200 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-sm`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <BeakerIcon className="h-6 w-6 text-indigo-600" />
                <span className={`ml-2 text-lg font-semibold transition-colors duration-200 ${
                  isDarkMode ? 'text-white' : 'text-gray-800'
                }`}>
                  Test e Configurazione del Modello
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Guide Section */}
      <div className={`transition-colors duration-200 ${isDarkMode ? 'bg-gray-800/50' : 'bg-white/50'} mt-6 rounded-lg mx-4`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <h2 className={`text-2xl font-bold transition-colors duration-200 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Guida all'Utilizzo
            </h2>
            <button
              onClick={() => setShowGuide(!showGuide)}
              className="ml-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {showGuide ? 'Nascondi Guida' : 'Mostra Guida'}
              {showGuide ? (
                <ChevronUpIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
              ) : (
                <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>

        {showGuide && (
          <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 mt-4 rounded-lg transition-colors duration-200 ${
            isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
          }`}>
            <PlaygroundGuide />
          </div>
        )}
      </div>
    </>
  );
};

export default PlaygroundHeader;