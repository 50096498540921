import React from 'react';
import { ChatBubbleBottomCenterTextIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

const DashboardGuide = () => {
  const guideSteps = [
    "Selezionate il formato del template (CSV/Word/PDF) in base alle vostre esigenze. Il CSV è consigliato per la massima compatibilità.",
    "Scegliete se espandere il dataset con l'opzione 'Espansione Dataset' per generare varianti delle domande (consigliato per dataset piccoli).",
    "Caricate i vostri file tramite drag&drop o selezione. Supportiamo file fino a 10MB.",
    "Esaminate l'anteprima dei dati, le statistiche e gli eventuali suggerimenti di miglioramento.",
    "Verificate le segnalazioni di problemi e applicate le correzioni suggerite per ottimizzare la qualità del dataset.",
    "Confermate la validità dei dati e avviate la conversione in formato JSONL per il fine-tuning.",
    "Monitorate il progresso della conversione attraverso la barra di avanzamento e i messaggi di stato.",
    "Controllate l'anteprima JSONL per verificare la corretta strutturazione dei dati.",
    "Inviate il dataset per il fine-tuning e monitorate il processo nella barra di stato.",
    "Una volta completato il fine-tuning, il vostro modello personalizzato sarà disponibile nel Playground per i test."
  ];

  return (
    <div className="space-y-6">
      <div className="prose dark:prose-invert max-w-none">
        <p className="text-gray-700 dark:text-gray-300">
          La Dashboard è il vostro centro di controllo per la preparazione e la gestione dei dataset per il fine-tuning. 
          Potete iniziare in due modi:
        </p>
        <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
          <li>
            <strong>Template predefiniti:</strong> Scaricate e utilizzate i nostri template in formato CSV, Word o PDF. 
            Questi template sono strutturati per garantire la massima efficacia nel processo di fine-tuning.
          </li>
          <li>
            <strong>Dataset esistente:</strong> Se disponete già di un dataset, assicuratevi che contenga:
            <ul className="list-disc pl-6 mt-2">
              <li>Coppie di domande e risposte chiaramente identificabili</li>
              <li>Testo pulito senza formattazioni speciali</li>
              <li>Almeno 50 coppie di domande e risposte per risultati ottimali</li>
              <li>Risposte complete e dettagliate</li>
            </ul>
          </li>
        </ul>
      <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg mt-4">
          <p className="text-blue-700 dark:text-blue-300 flex items-start">
            <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-2 flex-shrink-0 mt-1" />
            Non avete ancora un dataset? Utilizzate il nostro Chatbot Assistente per crearne uno personalizzato. 
            Cliccate sul pulsante "Switch to Chatbot" nella barra di navigazione superiore.
          </p>
        </div>
      </div>

      <div className="space-y-4">
        {guideSteps.map((step, i) => (
          <div key={i} className="flex items-start space-x-2">
            <span className="font-bold min-w-[24px] text-gray-700 dark:text-gray-300">
              {i + 1}.
            </span>
            <span className="text-gray-600 dark:text-gray-400">
              {step}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardGuide;