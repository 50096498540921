// DeleteConfirmationModal.js
import React from 'react';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, modelId }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4">
        <h2 className="text-xl font-bold mb-4 dark:text-white">Conferma eliminazione</h2>
        <div className="space-y-4">
          <p className="text-gray-600 dark:text-gray-300">
            Sei sicuro di voler eliminare questo modello dalla piattaforma?
            <br />
            <span className="text-sm font-mono mt-2 block">ID: {modelId}</span>
          </p>
          <div className="bg-yellow-50 dark:bg-yellow-900 p-3 rounded-md">
            <p className="text-sm text-yellow-800 dark:text-yellow-200">
              Nota: Questo rimuoverà il modello solo dalla piattaforma. Il modello rimarrà disponibile nel tuo account OpenAI.
            </p>
          </div>
        </div>
        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700"
          >
            Annulla
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Elimina
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;