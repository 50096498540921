import React, { useState } from 'react';
import { PlusCircle, Trash2 } from 'lucide-react';

const ProductServiceForm = ({ onSubmit, isDarkMode }) => {
  const [items, setItems] = useState([
    { 
      name: '', 
      description: '', 
      price: '', 
      category: '',
      variations: []
    }
  ]);

  const [showForm, setShowForm] = useState(false);

  const addItem = () => {
    setItems([...items, { 
      name: '', 
      description: '', 
      price: '', 
      category: '',
      variations: []
    }]);
  };

  const removeItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const addVariation = (itemIndex) => {
    const newItems = [...items];
    newItems[itemIndex].variations.push({ name: '', price: '' });
    setItems(newItems);
  };

  const removeVariation = (itemIndex, variationIndex) => {
    const newItems = [...items];
    newItems[itemIndex].variations.splice(variationIndex, 1);
    setItems(newItems);
  };

  const updateItem = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const updateVariation = (itemIndex, variationIndex, field, value) => {
    const newItems = [...items];
    newItems[itemIndex].variations[variationIndex][field] = value;
    setItems(newItems);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(items);
  };

  return (
    <div className={`w-full max-w-4xl mx-auto p-6 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
      <button
        onClick={() => setShowForm(!showForm)}
        className={`mb-6 px-4 py-2 rounded-lg ${
          isDarkMode 
            ? 'bg-blue-600 hover:bg-blue-700' 
            : 'bg-blue-500 hover:bg-blue-600'
        } text-white font-medium flex items-center gap-2`}
      >
        {showForm ? 'Nascondi' : 'Mostra'} Form Prodotti/Servizi
      </button>

      {showForm && (
        <form onSubmit={handleSubmit} className="space-y-6">
          {items.map((item, index) => (
            <div 
              key={index} 
              className={`p-6 rounded-lg ${
                isDarkMode 
                  ? 'bg-gray-800 border border-gray-700' 
                  : 'bg-white border border-gray-200'
              } shadow-lg`}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Prodotto/Servizio #{index + 1}</h3>
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removeItem(index)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                )}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  placeholder="Nome prodotto/servizio"
                  value={item.name}
                  onChange={(e) => updateItem(index, 'name', e.target.value)}
                  className={`w-full p-2 rounded border ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300'
                  }`}
                  required
                />

                <input
                  type="text"
                  placeholder="Categoria"
                  value={item.category}
                  onChange={(e) => updateItem(index, 'category', e.target.value)}
                  className={`w-full p-2 rounded border ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300'
                  }`}
                />

                <input
                  type="number"
                  placeholder="Prezzo base"
                  value={item.price}
                  onChange={(e) => updateItem(index, 'price', e.target.value)}
                  className={`w-full p-2 rounded border ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300'
                  }`}
                  required
                />

                <textarea
                  placeholder="Descrizione"
                  value={item.description}
                  onChange={(e) => updateItem(index, 'description', e.target.value)}
                  className={`w-full p-2 rounded border ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300'
                  }`}
                  rows={2}
                />
              </div>

              {/* Variazioni */}
              <div className="mt-4">
                <div className="flex items-center justify-between">
                  <h4 className="text-md font-medium">Variazioni</h4>
                  <button
                    type="button"
                    onClick={() => addVariation(index)}
                    className={`text-sm flex items-center gap-1 ${
                      isDarkMode ? 'text-blue-400' : 'text-blue-600'
                    }`}
                  >
                    <PlusCircle className="h-4 w-4" />
                    Aggiungi Variazione
                  </button>
                </div>

                {item.variations.map((variation, vIndex) => (
                  <div key={vIndex} className="mt-2 flex gap-2 items-center">
                    <input
                      type="text"
                      placeholder="Nome variazione"
                      value={variation.name}
                      onChange={(e) => updateVariation(index, vIndex, 'name', e.target.value)}
                      className={`flex-1 p-2 rounded border ${
                        isDarkMode 
                          ? 'bg-gray-700 border-gray-600 text-white' 
                          : 'bg-white border-gray-300'
                      }`}
                    />
                    <input
                      type="number"
                      placeholder="Prezzo"
                      value={variation.price}
                      onChange={(e) => updateVariation(index, vIndex, 'price', e.target.value)}
                      className={`w-32 p-2 rounded border ${
                        isDarkMode 
                          ? 'bg-gray-700 border-gray-600 text-white' 
                          : 'bg-white border-gray-300'
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => removeVariation(index, vIndex)}
                      className="text-red-500 hover:text-red-600"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div className="flex gap-4 justify-between">
            <button
              type="button"
              onClick={addItem}
              className={`px-4 py-2 rounded ${
                isDarkMode 
                  ? 'bg-gray-700 hover:bg-gray-600 text-white' 
                  : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
              } flex items-center gap-2`}
            >
              <PlusCircle className="h-5 w-5" />
              Aggiungi Prodotto/Servizio
            </button>

            <button
              type="submit"
              className={`px-6 py-2 rounded ${
                isDarkMode 
                  ? 'bg-green-600 hover:bg-green-700' 
                  : 'bg-green-500 hover:bg-green-600'
              } text-white font-medium`}
            >
              Genera Domande e Risposte
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ProductServiceForm;
