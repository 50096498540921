import React, { useState, useEffect, useRef } from 'react';
import { TrashIcon as DeleteIcon } from '@heroicons/react/24/outline';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import axios from 'axios';
import DeployPreview from './DeployPreview';
import PlaygroundHeader from './PlaygroundHeader';
import { 
  ArrowUpCircleIcon,
  TrashIcon, 
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/24/outline';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const ModelPlayground = ({ token, isDarkMode }) => {
  const [savedModels, setSavedModels] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modelToDelete, setModelToDelete] = useState(null);
  const [selectedModel, setSelectedModel] = useState('');
  const [currentModelDetails, setCurrentModelDetails] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(true);
  const [temperature, setTemperature] = useState(0.7);
  const [maxTokens, setMaxTokens] = useState(150);
  const messageEndRef = useRef(null);
  const [systemPrompt, setSystemPrompt] = useState('');
  const [isEditingPrompt, setIsEditingPrompt] = useState(false);
  const DEFAULT_SYSTEM_PROMPT = `Sei un agente di supporto dell'impresa [inserisci sostituendo questo testo con il nome della tua impresa e poi salva il prompt]. La tua funzione principale è assistere i clienti in caso di problemi con i nostri prodotti o servizi. Dovete ascoltare attivamente, comprendere non solo il contenuto esplicito delle richieste dei clienti, ma anche individuare i segnali più sottili che indicano frustrazione o confusione. Rispondere con soluzioni non solo efficaci, ma anche comunicate con calore e comprensione. Se un cliente si arrabbia, mantenete la calma e la calma, utilizzando tecniche de-escalation per calmare il cliente. Il vostro obiettivo è risolvere i problemi in modo rapido, efficiente e con un tocco personale che faccia sentire i clienti ascoltati e accuditi.`;

  useEffect(() => {
    if (token) {
      fetchSavedModels();
    }
  }, [token]);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (selectedModel) {
      setMessages([]); // Reset chat when model changes
      const model = savedModels.find(m => m.fine_tuned_model === selectedModel);
      setCurrentModelDetails(model);
      if (model?.system_prompt) {
        setSystemPrompt(model.system_prompt);
      }
      console.log('Selected model changed:', selectedModel);
    }
  }, [selectedModel, savedModels]);

  const fetchSavedModels = async () => {
    try {
      console.log('Fetching models...');
      const response = await axios.get(`${API_BASE_URL}/playground/fine-tuned-models`, {  
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.models && response.data.models.length > 0) {
        console.log('Models fetched:', response.data.models);
        setSavedModels(response.data.models);
        setSelectedModel(response.data.models[0].fine_tuned_model);
        
        const initialPrompt = response.data.models[0].system_prompt || DEFAULT_SYSTEM_PROMPT;
        setSystemPrompt(initialPrompt);
        
        if (!response.data.models[0].system_prompt) {
          await axios.put(
            `${API_BASE_URL}/playground/models/${encodeURIComponent(response.data.models[0].fine_tuned_model)}/system-prompt`,
            { 
              system_prompt: DEFAULT_SYSTEM_PROMPT 
            },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            }
          );
          setMessages(prev => [...prev, {
            role: 'system',
            content: 'Prompt di sistema predefinito impostato.'
          }]);
        }
      }
    } catch (error) {
      console.error('Error fetching models:', error);
      setMessages(prev => [...prev, {
        role: 'system',
        content: `Errore nel caricamento dei modelli: ${error.response?.data?.detail || error.message}`
      }]);
    }
  };

  const handleModelChange = async (event) => {
    const newModelId = event.target.value;
    console.log('Changing model to:', newModelId);
    setSelectedModel(newModelId);
    setMessages([]); // Reset chat
    
    try {
      const model = savedModels.find(m => m.fine_tuned_model === newModelId);
      if (!model) {
        throw new Error('Modello non trovato');
      }
      
      setSystemPrompt(model.system_prompt || DEFAULT_SYSTEM_PROMPT);
      
      setMessages([{
        role: 'system',
        content: `Modello selezionato: ${newModelId}`
      }]);
    } catch (error) {
      console.error('Error changing model:', error);
      setMessages([{
        role: 'system',
        content: `Errore nel cambio del modello: ${error.message}`
      }]);
    }
  };
    
    
  // Aggiungi queste funzioni nel componente
const handleDeleteModel = async (modelId) => {
  setModelToDelete(modelId);
  setIsDeleteModalOpen(true);
};

const confirmDelete = async () => {
  try {
    await axios.delete(
      `${API_BASE_URL}/playground/models/${encodeURIComponent(modelToDelete)}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    // Aggiorna la lista dei modelli
    await fetchSavedModels();
    
    // Se il modello eliminato era quello selezionato, resetta la selezione
    if (selectedModel === modelToDelete) {
      setSelectedModel('');
      setMessages([]);
    }

    setMessages(prev => [...prev, {
      role: 'system',
      content: 'Modello eliminato con successo.',
      timestamp: new Date().toISOString()
    }]);

  } catch (error) {
    console.error('Errore nell\'eliminazione del modello:', error);
    setMessages(prev => [...prev, {
      role: 'system',
      content: `Errore nell'eliminazione del modello: ${error.response?.data?.detail || error.message}`,
      timestamp: new Date().toISOString()
    }]);
  } finally {
    setIsDeleteModalOpen(false);
    setModelToDelete(null);
  }
};    
    
  const handleSendMessage = async () => {
    if (!inputMessage.trim() || !selectedModel) return;

    const newMessage = {
      role: 'user',
      content: inputMessage,
      timestamp: new Date().toISOString()
    };

    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      console.log('Sending message to model:', selectedModel);
      const response = await axios.post(
        `${API_BASE_URL}/playground/chat`,
        {
          model: selectedModel,
          message: inputMessage,
          temperature,
          max_tokens: maxTokens,
          system_prompt: systemPrompt
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Response received:', response.data);

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      setMessages(prev => [...prev, {
        role: 'assistant',
        content: response.data.response,
        model: selectedModel,
        timestamp: new Date().toISOString()
      }]);
    } catch (error) {
      console.error('Error in chat:', error);
      setMessages(prev => [...prev, {
        role: 'system',
        content: `Errore: ${error.response?.data?.detail || error.message}`,
        timestamp: new Date().toISOString()
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const clearChat = () => {
    console.log('Clearing chat');
    setMessages([{
      role: 'system',
      content: `Chat pulita. Modello attivo: ${selectedModel}`,
      timestamp: new Date().toISOString()
    }]);
  };

  const handleUpdateSystemPrompt = async () => {
    if (!selectedModel || !systemPrompt.trim()) return;
    
    try {
      console.log('Updating system prompt for model:', selectedModel);
      await axios.put(
        `${API_BASE_URL}/playground/models/${encodeURIComponent(selectedModel)}/system-prompt`,
        { 
          system_prompt: systemPrompt 
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      setIsEditingPrompt(false);
      setMessages(prev => [...prev, {
        role: 'system',
        content: 'Prompt di sistema aggiornato con successo.',
        timestamp: new Date().toISOString()
      }]);
    } catch (error) {
      console.error('Error updating system prompt:', error);
      setMessages(prev => [...prev, {
        role: 'system',
        content: `Errore nell'aggiornamento del prompt di sistema: ${error.response?.data?.detail || error.message}`,
        timestamp: new Date().toISOString()
      }]);
    }
  };

  // Rendering del componente
    return (
      <div className={`min-h-screen transition-colors duration-200 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
        <PlaygroundHeader isDarkMode={isDarkMode} />
        <div className="container mx-auto p-6">
          <div className="flex h-[calc(100vh)]">
            {/* Sidebar */}
            <div className={`${showSettings ? 'w-80' : 'w-12'} transition-colors duration-200 dark:bg-gray-800 bg-white border-r dark:border-gray-700 flex flex-col`}>
              <div className="p-4 border-b dark:border-gray-700 flex justify-between items-center">
                <h2 className={`font-semibold dark:text-white text-gray-800 ${!showSettings && 'hidden'}`}>
                  Impostazioni Modello
                </h2>
                <button
                  onClick={() => setShowSettings(!showSettings)}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors duration-200"
                >
                  {showSettings ? 
                    <ChevronUpIcon className="h-5 w-5 dark:text-gray-300" /> : 
                    <ChevronDownIcon className="h-5 w-5 dark:text-gray-300" />
                  }
                </button>
              </div>
    
              {showSettings && (
                <div className="p-4 space-y-6 flex-grow overflow-auto">
                  {/* Model Selection with Delete Button */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Seleziona Modello Fine-Tuned
                    </label>
                    <div className="relative">
                      <select
                        value={selectedModel}
                        onChange={handleModelChange}
                        className="w-full p-2 pr-10 border dark:border-gray-600 dark:bg-gray-700 dark:text-white border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors duration-200"
                      >
                        <option value="">Seleziona un modello</option>
                        {savedModels.map((model) => (
                          <option key={model.id} value={model.fine_tuned_model}>
                            {model.fine_tuned_model}
                          </option>
                        ))}
                      </select>
                      {selectedModel && (
                        <button
                          onClick={(e) => handleDeleteModel(selectedModel, e)}
                          className="absolute right-2 top-2 p-1 text-gray-400 hover:text-red-500 dark:text-gray-500 dark:hover:text-red-400 transition-colors duration-200"
                          title="Elimina modello"
                          disabled={isLoading}
                        >
                          <DeleteIcon className="h-5 w-5" />
                        </button>
                      )}
                    </div>
                  </div>
    
                  {/* Temperature Control */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Temperatura: {temperature}
                    </label>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.1"
                      value={temperature}
                      onChange={(e) => setTemperature(parseFloat(e.target.value))}
                      className="w-full dark:bg-gray-700"
                    />
                  </div>
    
                  {/* Max Tokens Control */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Max Tokens: {maxTokens}
                    </label>
                    <input
                      type="range"
                      min="50"
                      max="500"
                      step="50"
                      value={maxTokens}
                      onChange={(e) => setMaxTokens(parseInt(e.target.value))}
                      className="w-full dark:bg-gray-700"
                    />
                  </div>
    
                  {/* System Prompt */}
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Prompt di Sistema
                      </label>
                      <button
                        onClick={() => setIsEditingPrompt(!isEditingPrompt)}
                        className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-500 transition-colors duration-200"
                      >
                        {isEditingPrompt ? 'Annulla' : 'Modifica'}
                      </button>
                    </div>
                    
                    {isEditingPrompt ? (
                      <div className="space-y-2">
                        <textarea
                          value={systemPrompt}
                          onChange={(e) => setSystemPrompt(e.target.value)}
                          className="w-full p-2 border dark:border-gray-600 dark:bg-gray-700 dark:text-white border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors duration-200"
                          rows={4}
                          placeholder="Inserisci il prompt di sistema..."
                        />
                        <button
                          onClick={handleUpdateSystemPrompt}
                          className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                        >
                          Salva Prompt
                        </button>
                      </div>
                    ) : (
                      <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-md transition-colors duration-200">
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                          {systemPrompt || 'Nessun prompt di sistema impostato'}
                        </p>
                      </div>
                    )}
                  </div>
    
                  {/* Clear Chat Button */}
                  <button
                    onClick={clearChat}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
                  >
                    <TrashIcon className="h-5 w-5 mr-2" />
                    Pulisci Chat
                  </button>
                </div>
              )}
            </div>
    
            {/* Chat Area */}
            <div className="flex-1 flex flex-col dark:bg-gray-900 bg-gray-50 transition-colors duration-200">
              <div className="flex-1 overflow-y-auto p-4 space-y-4">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
                  >
                    <div
                      className={`max-w-[70%] p-3 rounded-lg transition-colors duration-200 ${
                        message.role === 'user'
                          ? 'bg-blue-600 text-white'
                          : message.role === 'system'
                          ? 'bg-red-100 dark:bg-red-900 text-red-900 dark:text-red-100'
                          : 'bg-white dark:bg-gray-800 dark:text-white shadow-sm'
                      }`}
                    >
                      {message.content}
                      {message.model && message.role === 'assistant' && (
                        <div className="text-xs mt-2 opacity-75">
                          Risposta da: {message.model}
                        </div>
                      )}
                      {message.timestamp && (
                        <div className="text-xs mt-1 opacity-50">
                          {new Date(message.timestamp).toLocaleTimeString()}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex justify-start">
                    <div className="bg-white dark:bg-gray-800 p-3 rounded-lg shadow-sm transition-colors duration-200">
                      <div className="flex items-center space-x-2">
                        <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"></div>
                        <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
                        <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
                      </div>
                    </div>
                  </div>
                )}
                <div ref={messageEndRef} />
              </div>
    
              {/* Input Area */}
              <div className="p-4 bg-white dark:bg-gray-800 border-t dark:border-gray-700 transition-colors duration-200">
                <div className="flex space-x-4">
                  <input
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    placeholder="Scrivi un messaggio..."
                    className="flex-1 p-2 border dark:border-gray-600 dark:bg-gray-700 dark:text-white border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors duration-200"
                    disabled={isLoading || !selectedModel}
                  />
                  <button
                    onClick={handleSendMessage}
                    disabled={isLoading || !inputMessage.trim() || !selectedModel}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                  >
                    <ArrowUpCircleIcon className="h-5 w-5" />
                  </button>
                </div>
                {!selectedModel && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                    Seleziona un modello per iniziare la chat
                  </p>
                )}
                {currentModelDetails && (
                  <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                    Modello attivo: {currentModelDetails.fine_tuned_model}
                  </p>
                )}
              </div>
            </div>
    
            {/* Deploy Preview */}
            {selectedModel && messages.length > 0 && (
              <DeployPreview 
                selectedModel={selectedModel}
                messages={messages}
                isDarkMode={isDarkMode}
              />
            )}
          </div>
    
          {/* Delete Confirmation Modal */}
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={confirmDelete}
            modelId={modelToDelete}
          />
        </div>
      </div>
    );
    };

export default ModelPlayground;