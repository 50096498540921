import React, { useState } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const Login = ({ setIsLoggedIn, setToken }) => {
  const [isLogin, setIsLogin] = useState(true); // Stato per gestire la modalità
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState(''); // Per la registrazione
  const [email, setEmail] = useState(''); // Per la registrazione
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('username', username);
      formData.append('password', password);

      const response = await axios.post(`${API_BASE_URL}/token`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const { access_token } = response.data;
      setToken(access_token); // Imposta il token per future richieste
      setIsLoggedIn(true); // Indica che l'utente è loggato
      localStorage.setItem('token', access_token); // Salva il token per sessioni future
    } catch (error) {
      setError('Credenziali non valide, riprova.');
    }
  };

  const handleRegister = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('email', email);
      formData.append('password', password);
      formData.append('full_name', fullName);

      const response = await axios.post(`${API_BASE_URL}/register`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      // Dopo la registrazione, puoi automaticamente loggare l'utente o mostrare un messaggio di successo
      setError('Registrazione avvenuta con successo! Ora puoi effettuare il login.');
      setIsLogin(true); // Torna alla modalità login
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setError(error.response.data.detail);
      } else {
        setError('Errore durante la registrazione. Riprova.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-sm w-full">
        <h2 className="text-2xl font-bold text-gray-900 mb-6 text-center">
          {isLogin ? 'Login' : 'Registrazione'}
        </h2>
        {!isLogin && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="fullName">
              Nome Completo
            </label>
            <input
              id="fullName"
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Inserisci il tuo nome completo"
            />
          </div>
        )}
        {!isLogin && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Inserisci la tua email"
            />
          </div>
        )}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="username">
            Username
          </label>
          <input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Inserisci il tuo username"
          />
        </div>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700" htmlFor="password">
            Password
          </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Inserisci la tua password"
          />
        </div>
        <div>
          <button
            onClick={isLogin ? handleLogin : handleRegister}
            className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isLogin ? 'Login' : 'Registrati'}
          </button>
        </div>
        {error && <p className="mt-4 text-sm text-red-500 text-center">{error}</p>}
        <div className="mt-4 text-center">
          {isLogin ? (
            <p>
              Non hai un account?{' '}
              <button
                onClick={() => {
                  setError('');
                  setIsLogin(false);
                }}
                className="text-indigo-600 hover:text-indigo-500 font-medium"
              >
                Registrati
              </button>
            </p>
          ) : (
            <p>
              Hai già un account?{' '}
              <button
                onClick={() => {
                  setError('');
                  setIsLogin(true);
                }}
                className="text-indigo-600 hover:text-indigo-500 font-medium"
              >
                Login
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;


