import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Dashboard from './Dashboard';
import Chatbot from './Chatbot';
import Login from './Login';
import ModelPlayground from './model-playground';
import { Sun as SunIcon, Moon as MoonIcon } from 'lucide-react';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [currentView, setCurrentView] = useState('dashboard');
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme') === 'dark' || 
    (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [isDarkMode]);

  const handleLogin = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setCurrentView('dashboard');
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleView = () => {
    setCurrentView(current => {
      switch (current) {
        case 'dashboard':
          return 'chatbot';
        case 'chatbot':
          return 'playground';
        default:
          return 'dashboard';
      }
    });
  };

  if (!isLoggedIn) {
    return <Login setIsLoggedIn={setIsLoggedIn} setToken={setToken} isDarkMode={isDarkMode} />;
  }

  return (
    <BrowserRouter>
      <div className="min-h-screen transition-colors duration-200 dark:bg-gray-900 bg-gray-100">
        <nav className="transition-colors duration-200 dark:bg-gray-800 bg-white shadow-lg">
          <div className="max-w-6xl mx-auto px-4">
            <div className="flex justify-between">
              <div className="flex space-x-7">
                <div>
                  <a href="#" className="flex items-center py-4 px-2 group">
                    <svg 
                      className="h-10 w-10 text-indigo-600 transition-transform duration-200 group-hover:scale-105" 
                      xmlns="http://www.w3.org/2000/svg" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      strokeWidth="1.5" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M21 7.5L18.75 6.187M21 7.5v2.25m0-2.25L18.75 8.813M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                      />
                    </svg>

                    <span className="font-semibold transition-all duration-200 text-indigo-600 text-lg ml-3 group-hover:text-indigo-500 dark:text-indigo-400 dark:group-hover:text-indigo-300">
                      FineTuningMax
                    </span>
                  </a>
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <button
                  onClick={toggleTheme}
                  className={`p-2 rounded-full transition-colors duration-200 ${
                    isDarkMode 
                      ? 'bg-gray-700 text-yellow-400 hover:bg-gray-600' 
                      : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
                  }`}
                  aria-label={isDarkMode ? 'Attiva tema chiaro' : 'Attiva tema scuro'}
                >
                  {isDarkMode ? (
                    <SunIcon className="h-5 w-5" />
                  ) : (
                    <MoonIcon className="h-5 w-5" />
                  )}
                </button>

                <button
                  onClick={toggleView}
                  className={`py-2 px-2 font-medium rounded transition-all duration-200 ${
                    isDarkMode 
                      ? 'text-gray-300 hover:bg-blue-600' 
                      : 'text-gray-500 hover:bg-blue-500 hover:text-white'
                  }`}
                >
                  {currentView === 'dashboard' 
                    ? 'Switch to Chatbot' 
                    : currentView === 'chatbot' 
                    ? 'Switch to Playground' 
                    : 'Switch to Dashboard'}
                </button>

                <button
                  onClick={handleLogout}
                  className={`py-2 px-2 font-medium rounded transition-all duration-200 ${
                    isDarkMode 
                      ? 'text-gray-300 hover:bg-red-600' 
                      : 'text-gray-500 hover:bg-red-500 hover:text-white'
                  }`}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </nav>

        <div className="container mx-auto p-6">
          <div className="transition-colors duration-200 dark:bg-gray-800 bg-white rounded-lg shadow-lg p-6">
            {currentView === 'dashboard' ? (
              <Dashboard 
                isLoggedIn={isLoggedIn} 
                token={token} 
                onLogout={handleLogout}
                isDarkMode={isDarkMode}
              />
            ) : currentView === 'chatbot' ? (
              <Chatbot token={token} isDarkMode={isDarkMode} />
            ) : (
              <ModelPlayground token={token} isDarkMode={isDarkMode} />
            )}
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;