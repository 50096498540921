// PlaygroundGuide.js
import React from 'react';

const PlaygroundGuide = () => {
  const guideSteps = [
    "Selezionate il vostro modello fine-tuned dalla lista dei modelli disponibili nel menu a tendina.",
    "Nel prompt di sistema, sostituite [nome azienda] con il nome della vostra attività e personalizzate il testo.",
    "Regolate la 'temperatura' (0.1-1.0): valori più bassi per risposte più precise, più alti per maggiore creatività.",
    "Impostate 'max tokens' per controllare la lunghezza massima delle risposte generate.",
    "Testate il modello con diverse tipologie di domande per verificare la coerenza delle risposte.",
    "Verificate che le risposte mantengano il tono e lo stile della vostra azienda.",
    "Utilizzate il pulsante 'Pulisci Chat' per iniziare nuovi test con impostazioni diverse.",
    "Una volta soddisfatti delle risposte, salvate le impostazioni ottimali per il deployment."
  ];

  return (
    <div className="space-y-6">
      <div className="prose dark:prose-invert max-w-none">
        <p className="text-gray-700 dark:text-gray-300">
          Il Playground è l'ambiente dove potete testare e ottimizzare il vostro modello fine-tuned. 
          Qui potrete verificare come il modello risponde a diverse domande e regolare i parametri 
          per ottenere le risposte più appropriate per la vostra attività.
        </p>
        <div className="bg-purple-50 dark:bg-purple-900/30 p-4 rounded-lg mt-4">
          <h4 className="text-purple-800 dark:text-purple-300 font-medium">
            Prompt di Sistema:
          </h4>
          <p className="text-purple-700 dark:text-purple-400">
            Il prompt di sistema predefinito è impostato per fornire risposte professionali e cortesi. È fondamentale:
          </p>
          <ul className="list-disc pl-6 text-purple-700 dark:text-purple-400">
            <li>Sostituire [nome azienda] con il nome della vostra attività</li>
            <li>Modificare il tono e lo stile per riflettere la vostra comunicazione</li>
            <li>Aggiungere dettagli specifici sul vostro approccio al servizio clienti</li>
            <li>Personalizzare le tecniche di de-escalation per situazioni difficili</li>
            <li>Adattare il linguaggio al vostro settore specifico</li>
          </ul>
        </div>
        <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg mt-4">
          <h4 className="text-blue-800 dark:text-blue-300 font-medium">
            Suggerimenti per il Testing:
          </h4>
          <ul className="list-disc pl-6 text-blue-700 dark:text-blue-400">
            <li>Testate scenari comuni del servizio clienti</li>
            <li>Provate diverse formulazioni della stessa domanda</li>
            <li>Verificate la gestione di richieste complesse</li>
            <li>Controllate la coerenza delle risposte nel tempo</li>
            <li>Testate la capacità di mantenere il contesto della conversazione</li>
          </ul>
        </div>
      </div>

      <div className="space-y-4">
        {guideSteps.map((step, i) => (
          <div key={i} className="flex items-start space-x-2">
            <span className="font-bold min-w-[24px] text-gray-700 dark:text-gray-300">
              {i + 1}.
            </span>
            <span className="text-gray-600 dark:text-gray-400">
              {step}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlaygroundGuide;