// DeployPreview.js
import React, { useState } from 'react';
import { 
  Code as CodeBracketIcon,
  Smartphone as DevicePhoneMobileIcon, 
  Monitor as ComputerDesktopIcon, 
  Eye as EyeIcon, 
  ClipboardCopy as ClipboardDocumentIcon 
} from 'lucide-react';

const DeployPreview = ({ selectedModel, messages }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [previewDevice, setPreviewDevice] = useState('desktop');
  const [copied, setCopied] = useState(false);

  const iframeCode = `<!-- FinetuningMax Chatbot Widget -->
<iframe
  src="https://finetuningmax.com/widget/${selectedModel}"
  style="position: fixed; bottom: 20px; right: 20px; width: 400px; 
  height: 600px; border: none; border-radius: 10px; box-shadow: 0 4px 12px rgba(0,0,0,0.15);"
>
</iframe>`;

  const handleCopy = () => {
    navigator.clipboard.writeText(iframeCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="fixed bottom-0 right-0 p-4 space-y-4">
      {!showPreview ? (
        <button
          onClick={() => setShowPreview(true)}
          className="flex items-center gap-2 px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-all shadow-lg"
        >
          <EyeIcon className="w-5 h-5" />
          Preview Widget
        </button>
      ) : (
        <div className="bg-white rounded-lg shadow-2xl w-[800px]">
          <div className="p-4 border-b">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold text-gray-900">Deploy Your Chatbot</h3>
              <button
                onClick={() => setShowPreview(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
          </div>

          <div className="p-6">
            {/* Device Toggle */}
            <div className="flex justify-center gap-4 mb-6">
              <button
                onClick={() => setPreviewDevice('desktop')}
                className={`flex items-center gap-2 px-4 py-2 rounded ${
                  previewDevice === 'desktop' ? 'bg-blue-100 text-blue-700' : 'text-gray-600'
                }`}
              >
                <ComputerDesktopIcon className="w-5 h-5" />
                Desktop
              </button>
              <button
                onClick={() => setPreviewDevice('mobile')}
                className={`flex items-center gap-2 px-4 py-2 rounded ${
                  previewDevice === 'mobile' ? 'bg-blue-100 text-blue-700' : 'text-gray-600'
                }`}
              >
                <DevicePhoneMobileIcon className="w-5 h-5" />
                Mobile
              </button>
            </div>

            {/* Preview Window */}
            <div className={`bg-gray-100 rounded-lg p-4 mb-6 ${
              previewDevice === 'mobile' ? 'max-w-[375px] mx-auto' : ''
            }`}>
              <div className={`bg-white rounded-lg shadow-lg overflow-hidden ${
                previewDevice === 'mobile' ? 'w-[320px]' : 'w-[400px]'
              } mx-auto`}>
                <div className="bg-blue-600 p-3 text-white">
                  <h4 className="text-sm font-medium">Company Assistant</h4>
                </div>
                <div className="h-[400px] overflow-y-auto p-4">
                  {messages.map((msg, index) => (
                    <div key={index} className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'} mb-3`}>
                      <div className={`max-w-[70%] p-3 rounded-lg ${
                        msg.role === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-100'
                      }`}>
                        {msg.content}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Integration Code */}
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-sm font-medium text-gray-700">Integration Code</h4>
                <button
                  onClick={handleCopy}
                  className="flex items-center gap-1 text-blue-600 hover:text-blue-800"
                >
                  <ClipboardDocumentIcon className="w-4 h-4" />
                  {copied ? 'Copied!' : 'Copy Code'}
                </button>
              </div>
              <pre className="bg-gray-900 text-gray-100 p-4 rounded-lg text-sm overflow-x-auto">
                {iframeCode}
              </pre>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeployPreview;